import React from 'react'
import avatar from '../assets/profileAvatar.png'


export default function Receiver({ message, isFirst, receiverName }) {
    return (
        <div class={`d-flex justify-content-end ${isFirst ? "mb-4 mt-3" : "mb-4"} `}>
            <div class="msg_cotainer_send ">
                {isFirst && (
                    <span class="msg_time_send" style={{ display: "flex", alignItems: "center", fontFamily: "'Inter', sans-serif", fontSize: "13px", fontWeight: "600" }}>
                        <img
                            src={avatar}
                            className="rounded-circle"
                            style={{ width: "18px", marginRight: "5px" }}
                        />
                        <span style={{ whiteSpace: "nowrap", fontFamily: "'Inter', sans-serif", }}>{receiverName}</span>
                    </span>
                )}

                <span style={{ fontFamily: "'Inter', sans-serif", fontSize: "15px", fontWeight: "500" }}> {message}  </span>
            </div>

        </div>
    )
}
