import './App.css';
import Sender from './components/Sender';
import Receiver from './components/Receiver';
import logo from './logo.png';
import mLogo from './assets/mLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from "react";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { IoSendSharp } from 'react-icons/io5';
import axios from 'axios';

function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [chats, setChats] = useState([]);
  const [userMessage, setUserMessage] = useState("");

  useEffect(() => {
    document.title = 'Turan App Chatbot | Orbina AI';
  }, []);

  const [dot3Render, setdot3Render] = useState(false);
  const postFoo = async ({ param = null }) => {
    var newUserMessage = param !== null ? param : userMessage;
    setUserMessage("");
    var temp = [...chats];
    temp.push({ role: "user", content: newUserMessage });
    setChats(temp);
    setdot3Render(true);
    try {

      let data = JSON.stringify({
        "messages": temp
        
      });
      console.log(data);
      const config = {
        url: 'https://azureapi.orbina.ai/bukytalk/chat/turan',
        headers: { 
          'Authorization': 'b05041d37c5eebbce34563524e15dcb2', 
          'Content-Type': 'application/json'
        }
      };
      
      const response = await axios.post(config.url, data, config)      
      var temp = [...chats];
      temp.push(
        { role: "user", content: newUserMessage },
        { role: "assistant", content: response?.data?.content }
      );
      
      setChats(temp);

    } catch (error) {
      console.log(error);
    } finally {
      setdot3Render(false);
    }
  };

  return (
    <>
      <div class="container-fluid h-100" style={{ minHeight: "100vh", maxHeight: "100vh" }}>

        <div class="row justify-content-center h-100" style={{ minHeight: "100vh", maxHeight: "100vh" }}>
          <div class="col-md-7 col-lg-6 col-xl-5 col-xxl-4 chat h-100" style={{ maxWidth: "470px", borderRadius: "none" }}>
            <div class="card h-100" style={{ border: "none", minHeight: "94vh", maxHeight: "94vh", marginTop: "3vh" }}>
              <div class="card-header msg_head">
                <div class="d-flex bd-highlight">
                  <div class="img_cont">
                    <img src={mLogo} class="rounded-circle user_img" />
                    {/* <span class="online_icon"></span> */}
                  </div>
                  <div class="user_info">
                    <span style={{ fontWeight: "700", marginBottom: "2rem" }}>Turan App Ekibi</span>
                    <p>Online</p>
                  </div>
                  <div class="video_cam">
                    <span><i class="fas fa-video"></i></span>
                    <span><i class="fas fa-phone"></i></span>
                  </div>
                </div>
                <span id="action_menu_btn"><i class="fas fa-ellipsis-v"></i></span>
                <div class="action_menu">
                  <ul>
                    <li><i class="fas fa-user-circle"></i> View profile</li>
                    <li><i class="fas fa-users"></i> Add to close friends</li>
                    <li><i class="fas fa-plus"></i> Add to group</li>
                    <li><i class="fas fa-ban"></i> Block</li>
                  </ul>
                </div>
              </div>
              <div class="card-body msg_card_body" style={{ backgroundColor: "white" }}>


                {chats.map((item, index) => (
                  item?.role === "user" ? (
                    <Receiver isFirst={true} receiverName="Destek Kullanıcısı" key={index} message={item.content} />
                  ) : (
                    <Sender isFirst={true} senderName="Turan App Ekibi" key={index} message={item.content} />
                  )
                ))}

                {dot3Render && <div className="col-3">
                  <div className="snippet" data-title="dot-pulse">
                    <div className="stage mx-2 pl-2">
                      <div className="dot-pulse"></div>
                    </div>
                  </div>
                </div>}


              </div>
              <div className='row d-flex justify-content-center' >
                <div className='row d-flex justify-content-center mt-2' style={{ maxWidth: "95%" }}>


                  {/* {examplePrompts.slice(0, 2).map(item => (
                    <div className={`col mx-1`} style={{ fontSize: "10px", border: "2px #78369C solid", borderRadius: "8px", backgroundColor: "white", cursor: "pointer", color: "#78369C", textAlign: "center" }}>
                      <div className='py-1 px-1'>
                        Lorem Ipsum
                      </div>
                    </div>
                  ))} */}

                </div>
              </div>

              <form onSubmit={handleSubmit(postFoo)} style={{ width: "100%", backgroundColor: "white" }}>
                <div class="card-footer text-muted d-flex justify-content-start align-items-center p-3" style={{ width: "100%", backgroundColor: "white" }}>
                  <div className="position-relative mb-0 w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      onChange={(e) => setUserMessage(e?.target?.value)}
                      value={userMessage}
                      style={{
                        color: userMessage ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.45)",
                        paddingRight: "2.5rem",
                        fontSize: "13px",
                        backgroundColor: "blur",
                        paddingTop: ".6rem",
                        paddingBottom: ".6rem",
                        border: "1px solid rgba(106, 90, 205, 0.45)",
                      }}
                      placeholder="Hoşgeldiniz 👋 Size nasıl yardımcı olabilirim?"
                      aria-label=""
                      aria-describedby="button-addon2"
                    />

                    <div className="position-absolute top-0 end-0 d-flex align-items-center h-100 px-2">
                      <button
                        className="btn py-1"
                        type="submit"
                        id="button-addon2"
                        style={{
                          backgroundColor: "#78369C",
                          color: "white",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center", // İkonu dikey olarak ortalar
                          justifyContent: "center", // İkonu yatay olarak ortalar
                          borderRadius: "11px",
                        }}
                      >
                        <div style={{ fontSize: '16px', display: "flex", alignItems: "center" }}>
                          <IoSendSharp style={{ margin: "auto" }} />
                        </div>
                      </button>
                    </div>
                  </div>


                </div>
              </form>
              <hr></hr>
              <div className='row d-flex justify-content-center' >
                <div className='row d-flex justify-content-center' style={{ maxWidth: "95%" }}>

                  <div className={`col-6`}>
                    <div className='p-1 px-2' style={{ backgroundColor: "white", cursor: "pointer", textAlign: "center", fontSize: "12px", margin: "0.5rem" }}>
                      Powered by {" "}
                      <img src={logo} width="25px" />
                      <strong> ORBİNA  </strong>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
