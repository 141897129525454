import React from 'react'
import avatar from '../assets/mLogo.png'

function NewLineText({ text }) {
    return text.split('\n').map((str, index, array) => (
      <span key={index}>
        {str}
        {index < array.length - 1 && <br />}
      </span>
    ));
}

export default function Sender({ message, isFirst, senderName }) {
    return (
        <div className={`d-flex justify-content-start ${isFirst ? "mb-5 mt-2" : "mb-1"} `}>
            <div className="msg_cotainer">
                {isFirst && (
                    <span class="msg_time" style={{ display: "flex", alignItems: "center", fontFamily: "'Inter', sans-serif", fontSize: "13px",fontWeight:"600"  }}>
                        <img
                            src={avatar}
                            className="rounded-circle"
                            style={{ width: "18px", marginRight: "5px" }}
                        />
                        <span style={{ color: "rgb(66, 91, 118)", whiteSpace: "nowrap",fontFamily: "'Inter', sans-serif",}}>{senderName}</span>
                    </span>
                )}

                <span style={{ color:"rgb(66, 91, 118)", fontFamily: "'Inter', sans-serif", fontSize: "15px",fontWeight:"500" }}> <NewLineText text={message} /> </span>
            </div>

        </div>
    )
}
